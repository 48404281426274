import clsx from 'clsx';

import type { ClassNameProps } from '@/types/common.types';

import { formatTime } from '@/helpers/format-time.helpers';

import styles from '@/components/ProgressBar/ProgressBar.module.scss';

interface ProgressBarProps extends ClassNameProps {
  variant?: 'light' | 'signature' | 'primary';
  max: number;
  value: number;
  currentTime?: number;
  duration?: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { variant = 'light', className, value, max, duration } = props;

  const percentage = Math.min((value * 100) / max, 100);

  return (
    <div className={clsx(styles.progressBar, className, styles[variant])}>
      <div className={styles.time}>
        {/* <span className={styles.timer}>{formatTime(currentTime)}</span> */}
        {duration && <span className={styles.timer}>{formatTime(duration, true)}</span>}
      </div>
      <div className={styles.progress}>
        <div style={{ width: `${percentage}%` }} className={styles.progressInner} />
      </div>
    </div>
  );
};
