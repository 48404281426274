'use client';

import { Fragment } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useMedia } from 'react-use';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';

import { breakpoints } from '@/app.config';
import ImdbSVG from '@/icons/ImdbSVG';
import KinopoiskSVG from '@/icons/KinopoiskSVG';
import { ClassNameProps } from '@/types/common.types';

import { RandomMovieResponse } from '@/views/HomeView/RandomMovie/useRandomMovie';

import { formatTime } from '@/helpers/format-time.helpers';

import { CardImage } from '@/components/Card';
import { ForwardedLink } from '@/components/ForwardedLink';
import { TruncatedText } from '@/components/TruncatedText';

import {
  RandomCardActions,
  RandomCardActionsProps,
} from '@/views/HomeView/RandomMovie/RandomCardActions';
import styles from '@/views/HomeView/RandomMovie/RandomSection.module.scss';

// =================================================================

interface RandomSectionProps
  extends ClassNameProps,
    Pick<RandomCardActionsProps, 'isLoading' | 'refetch'> {
  movieDetails: RandomMovieResponse;
}

export enum AspectRatioEnum {
  TABLET = 'tablet',
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

// =================================================================

const CARD_ASPECT_RATIO = 343 / 507;
const CARD_ASPECT_RATIO_FOR_TABLET = 252 / 373;
const CARD_ASPECT_RATIO_FOR_MOBILE = 376 / 556;

// =================================================================

export const RandomSection = (props: RandomSectionProps) => {
  const { isLoading, refetch, movieDetails, className } = props;
  const t = useTranslations('common');

  const router = useRouter();

  const [isTablet, isMobile] = [
    useMedia(breakpoints.large, false),
    useMedia(breakpoints.medium, false),
  ];

  const getAspectRatio: Record<AspectRatioEnum, number> = {
    [AspectRatioEnum.TABLET]: CARD_ASPECT_RATIO_FOR_TABLET,
    [AspectRatioEnum.MOBILE]: CARD_ASPECT_RATIO_FOR_MOBILE,
    [AspectRatioEnum.DESKTOP]: CARD_ASPECT_RATIO,
  };

  const handleNavigate = () => {
    router.push(`/watch/${movieDetails?.moduleId}/${movieDetails?.id}`);
  };

  return (
    <div className={clsx(styles.randomMovieWrapper, className)}>
      <div className={styles.movieDetails}>
        <div className={styles.movieInfo}>
          <TruncatedText as="h3" lines={isTablet ? 2 : 1} className={styles.movieTitle}>
            {isLoading ? (
              <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={160}>
                <Skeleton />
              </SkeletonTheme>
            ) : (
              <Fragment>{movieDetails?.title}</Fragment>
            )}
          </TruncatedText>

          <TruncatedText as="p" lines={isTablet ? 3 : 2} className={styles.description}>
            {isLoading ? (
              <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={290}>
                <Skeleton count={2} />
              </SkeletonTheme>
            ) : (
              <Fragment>{movieDetails?.description}</Fragment>
            )}
          </TruncatedText>

          <ul className={styles.detailsInfoWrapper}>
            {/* Countries */}
            <li>
              <p className={styles.label}>{t('countries')}:</p>
              <div className={styles.linkBtnWrapper}>
                {isLoading ? (
                  <SkeletonTheme
                    baseColor="#0F0F12"
                    highlightColor="#47474A"
                    width={100}
                    height={22}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                ) : (
                  <Fragment>
                    {movieDetails?.countries.map(country => (
                      <ForwardedLink key={country.id} href={`/country/${country.id}`}>
                        {country.title}
                      </ForwardedLink>
                    ))}
                  </Fragment>
                )}
              </div>
            </li>
            {/* Ratings */}
            <li>
              <p className={styles.label}>{t('ratings')}:</p>
              <div className={styles.ratingWrapper}>
                {isLoading ? (
                  <SkeletonTheme
                    baseColor="#0F0F12"
                    highlightColor="#47474A"
                    width={100}
                    height={22}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                ) : (
                  <Fragment>
                    {movieDetails?.rating?.imdb && (
                      <div className={styles.rating}>
                        <ImdbSVG />
                        <p className={styles.infoText}>{movieDetails?.rating.imdb.rating}</p>
                      </div>
                    )}
                    {movieDetails?.rating?.kp && (
                      <div className={styles.rating}>
                        <KinopoiskSVG />
                        <p className={styles.infoText}>{movieDetails?.rating.kp.rating}</p>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </li>
            {/* Genres */}
            <li>
              <p className={styles.label}>{t('genres')}:</p>
              <div className={styles.linkBtnWrapper}>
                {isLoading ? (
                  <SkeletonTheme
                    baseColor="#0F0F12"
                    highlightColor="#47474A"
                    width={100}
                    height={22}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                ) : (
                  <Fragment>
                    {movieDetails?.genres.map(genre => (
                      <ForwardedLink
                        key={genre.id}
                        href={`/genre/${genre.id}`}
                        className={styles.linkBtn}
                      >
                        {genre.title}
                      </ForwardedLink>
                    ))}
                  </Fragment>
                )}
              </div>
            </li>
            {/* Languages */}
            {movieDetails?.languages?.length > 0 && (
              <li>
                <p className={styles.label}>{t('languages')}:</p>
                <div className={styles.linkBtnWrapper}>
                  {isLoading ? (
                    <SkeletonTheme
                      baseColor="#0F0F12"
                      highlightColor="#47474A"
                      width={100}
                      height={22}
                    >
                      <Skeleton />
                    </SkeletonTheme>
                  ) : (
                    <Fragment>
                      {movieDetails?.languages.map((language, index) => (
                        <p key={index} className={clsx(styles.infoText, styles.capitalized)}>
                          {language}
                          {index !== movieDetails?.languages.length - 1 && ','}
                        </p>
                      ))}
                    </Fragment>
                  )}
                </div>
              </li>
            )}
            {/* Duration */}
            <li>
              <p className={styles.label}>
                {movieDetails?.isSerial ? t('seasons') : t('duration')}:
              </p>
              {isLoading ? (
                <SkeletonTheme baseColor="#0F0F12" highlightColor="#47474A" width={100} height={22}>
                  <Skeleton />
                </SkeletonTheme>
              ) : (
                <Fragment>
                  {movieDetails?.isSerial ? (
                    <p className={styles.infoText}>
                      {t('season', {
                        count: movieDetails?.seasons,
                      })}
                    </p>
                  ) : (
                    <p className={styles.infoText}>{formatTime(movieDetails?.duration, true)}</p>
                  )}
                </Fragment>
              )}
            </li>
          </ul>
        </div>

        <RandomCardActions
          isLoading={isLoading}
          refetch={refetch}
          moduleId={movieDetails?.moduleId}
          movieId={movieDetails?.id}
          fileId={movieDetails?.files[0].id}
          className={styles.actionsButton}
        />
      </div>
      <div className={styles.movieImageWrapper}>
        {isLoading ? (
          <CardImage
            as="div"
            alt={'random-movie-poster'}
            src={'/assets/images/movie-card-placeholder-xl.png'}
            aspectRatio={
              getAspectRatio[
                isTablet
                  ? AspectRatioEnum.TABLET
                  : isMobile
                    ? AspectRatioEnum.MOBILE
                    : AspectRatioEnum.DESKTOP
              ]
            }
            className={styles.movieImage}
          />
        ) : (
          <CardImage
            as="div"
            onClick={handleNavigate}
            src={movieDetails?.poster}
            aspectRatio={
              getAspectRatio[
                isTablet
                  ? AspectRatioEnum.TABLET
                  : isMobile
                    ? AspectRatioEnum.MOBILE
                    : AspectRatioEnum.DESKTOP
              ]
            }
            className={styles.movieImage}
          >
            <div className={styles.movieDetailsMobile}>
              <TruncatedText as="h3" lines={2} className={styles.movieTitle}>
                {movieDetails?.title}
              </TruncatedText>

              <RandomCardActions
                isLoading={isLoading}
                refetch={refetch}
                moduleId={movieDetails?.moduleId}
                movieId={movieDetails?.id}
                fileId={movieDetails?.files[0].id}
              />
            </div>
          </CardImage>
        )}
      </div>
    </div>
  );
};
