import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';

import { Locale } from '@/types/common.types';

import { HomeApi } from '@/api/domains/home.api';
import { MovieDetails, MovieDetailsInfo } from '@/api/types/movie.types';

import { HomeQueryKeys } from '@/contexts/QueryContext/query.keys';

export interface RandomMovieResponse extends MovieDetails {
  quality: string;
  duration: number;
  languages: string[];
  files: MovieDetailsInfo & { id: number }[];
}

export const useRandomMovie = () => {
  const locale = useLocale() as Locale;

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: HomeQueryKeys.randomMovie(locale),
    queryFn: () => HomeApi.fetchHomeRandomMovie(),
    select(data) {
      return {
        ...data.data,
        quality: data?.info[0]?.quality,
        duration: data?.info[0]?.duration,
        languages: data?.info[0]?.file_audio_info,
      };
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    movieDetails: data as RandomMovieResponse,
    isLoading: isFetching,
    refetch,
  };
};
